.main-container {
  display: grid;
  gap: 10%;
}
.main-container > img {
  height: 120px;
}
.main-text {
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.alternate-bg{
  background-image: linear-gradient(0deg, #3492d0 0%, #512d8a 120%);
}

@media screen and (min-width: 320px) {
  .main-text {
    font-size: calc(18px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 700px) {
  .main-text {
    font-size: 28px;
  }
  .main-container {
    padding-bottom: 75px;
  }
}
